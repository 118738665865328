import { Backdrop, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import ChoreoBaseSvg from './components/ChoreoBaseSvg';
import ChoreoAppBar from './controls/ChoreoAppBar';
import ChoreoSlider from './controls/ChoreoSlider';
import { compareTrack, getDancerPositions } from './utils/ChoreoUtils';

/*
 * Format des JSON vom Server:
 * {
 *   "id": "e63f9c6a-dd5a-4365-b8dd-a40629e60d39",
 *   "length": 360000,
 *   "dancers": {
 *     "1": {
 *       "id": 1,
 *       "name": "Peter",
 *       "color": "#0000FF"
 *     }
 *   },
 *   "timeMarkers": {
 *     "0": {
 *       "id": 11,
 *       "time": 0,
 *       "name": "Chacha",
 *       "positions": {
 *         "1": {
 *           "id": 21,
 *           "x": 1,
 *           "y": 2
 *         }
 *       }
 *     }
 *   }
 * } 
 */


function App() {
  const [track, setTrack] = useState(
    {
      "id": null,
      "length": 360_000,
      "music": null,
      "dancers": {
        "1": {
          "id": 1,
          "name": "Dancer 1",
          "color": "#4087D4"
        }
      },
      "timeMarkers": {
        "0": {
          "id": 1,
          "time": 0,
          "name": null,
          "positions": {
            "1": {
              "id": 1,
              "x": 0,
              "y": 0
            }
          }
        }
      }
    });
  const [savedTrack, setSavedTrack] = useState({});
  const [time, setTime] = useState(0);
  const [timeUi, setTimeUi] = useState(0);
  const [settings, setSettings] = useState({
    snap: 0.5,
    dancerRadius: 12
  });
  const [updateTrack, setUpdateTrack] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [synced, setSynced] = useState(false);
  const trackRef = useRef();
  trackRef.current = track;
  const savedTrackRef = useRef();
  savedTrackRef.current = savedTrack;
  const isPlayingRef = useRef();
  isPlayingRef.current = isPlaying;
  const timeRef = useRef();
  timeRef.current = time;
  const audioRef = useRef();

  function setPositions(positions) {
    setTrack({
      ...track,
      timeMarkers: {
        ...track.timeMarkers,
        [time]: {
          ...track.timeMarkers[time],
          time: time,
          positions: positions,
        }
      }
    });
  }

  function setTimeMarkers(timeMarkers) {
    setTrack({
      ...track,
      timeMarkers: timeMarkers
    });
  }

  /*
  function setDancers(dancers) {
    setTrack({
      ...track,
      dancers: dancers
    });
  }
*/
  useEffect(() => {
    const interval = setInterval(() => {
      let trackInternal = trackRef.current;
      let savedTrackInternal = savedTrackRef.current;
      let trackId = trackInternal.id;
      if (trackId !== null && !track.isReadOnly) {
        //let changes = getChangesTrack(trackInternal, savedTrackInternal);
        if (!compareTrack(trackInternal, savedTrackInternal)) {
          // Synchronisationslogik sollte noch deutlich aufgebohrt werden...
          setSavedTrack(trackInternal);
          const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(trackInternal)
          };
          fetch('/api/v1/track/' + trackId, requestOptions)
            .then(response => response.json())
            .then(data => {
              if (data.stack) {
                setSavedTrack({});
                setSynced(false);
              } else {
                let trackInternal = trackRef.current;
                let savedTrackInternal = savedTrackRef.current;
                if (compareTrack(trackInternal, savedTrackInternal)) {
                  setTrack(data);
                  setSynced(true);
                }
                setSavedTrack(data);
                setUpdateTrack(false);
              }
            })
            .catch((err) => {
              console.log(err.message);
              setSavedTrack({});
              setSynced(false);
            });
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const trackId = queryParameters.get("track");
    if (trackId !== null) {
      fetch('/api/v1/track/' + trackId)
        .then((res) => res.json())
        .then((data) => {
          setTrack(data);
          setSavedTrack(data);
          setSynced(true);
          setUpdateTrack(false);
        })
        .catch((err) => {
          console.log(err.message);
          setSynced(false);
          setUpdateTrack(false);
        });
    }
  }, [updateTrack]);

  let positions = getDancerPositions({ time, markers: track.timeMarkers });

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPlayingRef.current) {
        if (trackRef.current.music) {
          setTime(audioRef.current.currentTime * 1000);
        } else {
          setTime(timeRef.current + 50)
        }
      }
    }, 50);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let newTime = time;
    if (timeUi >= 0) {
      newTime = timeUi;
      setTimeUi(-1);
      setTime(newTime);
    }
    if (audioRef.current && track.music) {
      if (isPlaying) {
        audioRef.current.currentTime = newTime / 1000;
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying, audioRef, timeUi]);

  return (
    <div className="App">
      {track.music ? <audio src={"/api/v1/music/" + track.music} ref={audioRef} preload="auto" /> : <></> }
      <ChoreoAppBar
        isReadOnly={track.readOnly}
        synced={synced}
        settings={settings}
        setSettings={setSettings}
        positions={positions}
        markers={track.timeMarkers}
        setMarkers={setTimeMarkers}
        track={track}
        setTrack={setTrack}
        time={time}
        setUpdateTrack={setUpdateTrack}
        setTime={setTimeUi} />
      <Box style={{ height: "calc(100% - 64px)" }}>
        <Box style={{ height: "calc(100% - 100px)", backgroundImage: "url('/background.png')" }}>
          <ChoreoBaseSvg dancers={track.dancers}
            isReadOnly={track.readOnly}
            positions={positions}
            snap={settings.snap}
            dancerRadius={settings.dancerRadius}
            isOnMarker={track.timeMarkers.hasOwnProperty(time)}
            setPositions={setPositions} />
        </Box>
        <div style={{ height: "100px", margin: "0 5% 0 5%" }}>
          <ChoreoSlider
            isReadOnly={track.readOnly}
            time={timeUi >= 0 ? timeUi : time}
            setTime={setTimeUi}
            length={track.length}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            markers={track.timeMarkers}
            setMarkers={setTimeMarkers} />
        </div>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={updateTrack}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default App;
