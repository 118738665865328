import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import JoinFullOutlinedIcon from '@mui/icons-material/JoinFullOutlined';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SafetyDividerIcon from '@mui/icons-material/SafetyDivider';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Slider, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { formatMilliSeconds, getDancerPositions } from "../utils/ChoreoUtils";

export default function ChoreoSlider({
    time, setTime, length,
    isReadOnly = false,
    markers, setMarkers,
    isPlaying = false, setIsPlaying = () => null
}) {
    const [visibleRange, setVisibleRange] = useState(60000);
    const [deleteMarkerOpen, setDeleteMarkerOpen] = useState(false);
    const [isDragging, setIsDragging] = React.useState(false);
    const [startX, setStartX] = React.useState(0);
    const [startTime, setStartTime] = React.useState(0);
    const markersForSlider = [];

    for (let id in markers) {
        markersForSlider.push({
            value: markers[id].time,
            label: formatMilliSeconds(markers[id].time)
        });
    }

    function handlePointerDown(e) {
        e.preventDefault();
        setIsDragging(true);
        setStartX(e.clientX);
        setStartTime(time);
    }

    const elementRef = useRef()
    function handlePointerMove(e) {
        if (isDragging) {

            let boundingRect = elementRef.current.getBoundingClientRect();
            let timeDiff = visibleRange * (e.clientX - startX) / boundingRect.width;
            setTime(Math.min(Math.max(startTime - timeDiff, 0), length));
        }
    }

    function handlePointerUp() {
        setIsDragging(false);
    }

    function handlePrevious() {
        let before = 0;

        for (let markTimeId in markers) {
            let markTime = markers[markTimeId].time;
            if (markTime > before && markTime < time) {
                before = markTime;
            }
        }

        setIsPlaying(false);
        setTime(before);
    }

    function handleNext() {
        let after = Number.MAX_SAFE_INTEGER;

        for (let markTimeId in markers) {
            let markTime = markers[markTimeId].time;
            if (markTime < after && markTime > time) {
                after = markTime;
            }
        }
        if (after < Number.MAX_SAFE_INTEGER) {
            setIsPlaying(false);
            setTime(after);
        }
    }

    function handleToggleMarker() {
        let newTime = Math.round(time);
        setTime(newTime);

        if (!markers.hasOwnProperty(time)) {
            let newPositions = getDancerPositions({ time: newTime, markers });
            setMarkers({
                ...markers,
                [newTime]: {
                    ...markers[newTime],
                    time: newTime,
                    positions: newPositions,
                }
            });
        } else if (time > 0) {
            setDeleteMarkerOpen(true);
        }
    }

    function deleteMarker() {
        setDeleteMarkerOpen(false);
        let newMarkers = {
            ...markers
        };
        delete newMarkers[time];
        setMarkers(newMarkers);
    }

    function handleZoomIn() {
        setVisibleRange(Math.max(15_000, visibleRange / 2));
    }

    function handleZoomOut() {
        setVisibleRange(Math.min(480_000, visibleRange * 2));
    }

    function handleJoin() {
        if (!markers.hasOwnProperty(time)) {
            return;
        }
        let grouped = {};
        const marker = markers[time];
        for (let dancerId in marker.positions) {
            let position = marker.positions[dancerId];
            let key = position.x + ":" + position.y;
            if (!grouped.hasOwnProperty(key)) {
                grouped[key] = [dancerId];
            } else {
                grouped[key].push(dancerId);
            }
        }

        let positions = {};
        for (let groupId in grouped) {
            for (let key in grouped[groupId]) {
                let dancerId = grouped[groupId][key];
                let linked = [...grouped[groupId]];
                linked.splice(key, 1)
                positions[dancerId] = {
                    ...markers[time].positions[dancerId],
                    linked: linked
                };
            }
        }
        setMarkers({
            ...markers,
            [time]: {
                ...markers[time],
                positions: positions
            }
        });
    }

    function handleSeparate() {
        if (!markers.hasOwnProperty(time)) {
            return;
        }
        let positions = { ...markers[time].positions };
        for (let dancerId in positions) {
            positions[dancerId].linked = [];
        }
        setMarkers({
            ...markers,
            [time]: {
                ...markers[time],
                positions: positions
            }
        });
    }

    let before = 0;
    for (let markTimeId in markers) {
        let markTime = markers[markTimeId].time
        if (markTime > before && markTime <= time) {
            before = markTime;
        }
    }
    let markerName = markers[before].name;
    if (!markerName) {
        markerName = formatMilliSeconds(before);
    }

    return (
        <Container
            onPointerMove={handlePointerMove}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
            style={{ height: "100%" }}>
            <Container>
                <Typography display="inline-block" variant="body1" style={{ width: "100px", textAlign: "right", verticalAlign: "middle" }}>{formatMilliSeconds(time)}</Typography>
                <IconButton onClick={handlePrevious} aria-label="play/pause">
                    <SkipPreviousIcon />
                </IconButton>
                <IconButton onClick={() => setIsPlaying(!isPlaying)} aria-label="play/pause">
                    {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                </IconButton>
                <IconButton onClick={handleNext} aria-label="play/pause">
                    <SkipNextIcon />
                </IconButton>
                {!isReadOnly && <IconButton onClick={handleToggleMarker} aria-label="addMarker">
                    {markers.hasOwnProperty(time) ? <ImageNotSupportedIcon /> : <AddPhotoAlternateIcon />}
                </IconButton>}
                <IconButton onClick={handleZoomIn} aria-label="zoomIn">
                    <ZoomInIcon />
                </IconButton>
                <IconButton onClick={handleZoomOut} aria-label="zoomOut">
                    <ZoomOutIcon />
                </IconButton>
                {!isReadOnly && <IconButton onClick={handleJoin} aria-label="join" disabled={!markers.hasOwnProperty(time)}>
                    <JoinFullOutlinedIcon />
                </IconButton>}
                {!isReadOnly && <IconButton onClick={handleSeparate} aria-label="join" disabled={!markers.hasOwnProperty(time)}>
                    <SafetyDividerIcon />
                </IconButton>}
                <Typography display="inline-block" variant="body1" style={{ width: "200px", textAlign: "left", verticalAlign: "middle" }}>Bild: {markerName}</Typography>
            </Container>
            <Slider
                className='ChoreoSlider'
                ref={elementRef}
                aria-label="Custom marks"
                defaultValue={0}
                value={[0, time, length]}
                step={100}
                min={time - visibleRange / 2}
                max={time + visibleRange / 2}
                valueLabelDisplay="auto"
                valueLabelFormat={formatMilliSeconds}
                //onChange={handleChange}
                onPointerDown={handlePointerDown}
                marks={markersForSlider}
            />

            <Dialog
                keepMounted
                open={deleteMarkerOpen}
                onClose={() => setDeleteMarkerOpen(false)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <DialogTitle>Key Frame löschen?</DialogTitle>
                <DialogContent>
                    <Box style={{ paddingBottom: "20px" }}>
                        <Divider />
                    </Box>
                    <Box style={{ paddingBottom: "20px" }}>
                        <Typography variant="body1">Soll der Key Frame an Position {formatMilliSeconds(time)} wirklich gelöscht werden?</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{ margin: "10px" }} color="error"
                        onClick={() => deleteMarker()}>
                        Löschen</Button>
                    <Button variant="outlined" style={{ margin: "10px" }}
                        onClick={() => {
                            setDeleteMarkerOpen(false);
                        }}>Abbrechen</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
