/*
 * Utility functions for the choreo picture helper
 */

export function formatMilliSeconds(value) {
    value = Math.round(value);
    let minutes = String(Math.floor((value / 1000) / 60)).padStart(2, '0');
    let seconds = String(Math.floor((value / 1000) % 60)).padStart(2, '0');
    let tenthSecond = String(Math.floor((value / 100) % 10));

    return `${minutes}:${seconds}.${tenthSecond}`;
}

export function getDancerPositions({ time, markers }) {
    // Find the two timeslots, between which the time is
    let before = 0;
    let after = Number.MAX_SAFE_INTEGER;

    for (let markTimeId in markers) {
        let markTime = parseInt(markTimeId)
        if (markTime > before && markTime <= time) {
            before = markTime;
        }
        if (markTime < after && markTime > time) {
            after = markTime;
        }
    }

    let positions = {};
    let timePercentage = 1;
    if (after === Number.MAX_SAFE_INTEGER) {
        after = before;
    } else {
        timePercentage = 1 - (after - time) / (after - before);
    }
    for (let dancerId in markers[before].positions) {
        let positionBefore = markers[before].positions[dancerId];
        let positionAfter = markers[after].positions[dancerId];
        if (before === time) {
            positions[dancerId] =
            {
                ...positionBefore,
                x: (positionAfter.x - positionBefore.x) * timePercentage + positionBefore.x,
                y: (positionAfter.y - positionBefore.y) * timePercentage + positionBefore.y,
            };
        } else {
            positions[dancerId] =
            {
                x: (positionAfter.x - positionBefore.x) * timePercentage + positionBefore.x,
                y: (positionAfter.y - positionBefore.y) * timePercentage + positionBefore.y,
            };
        }
    }

    return positions;
}

export function compareTrack(track1, track2) {
    try {
        return track1.length === track2.length
            && track1.name === track2.name
            && compareTimeMarkers(track1.timeMarkers, track2.timeMarkers)
            && compareDancers(track1.dancers, track2.dancers)
    } catch (error) {
        console.log("Error during comparison:" + error);
        return false;
    }
}

export function compareDancers(d1, d2) {
    if (Object.keys(d1).length !== Object.keys(d2).length) {
        return false;
    }
    for (let dancerId in d1) {
        if (!compareDancer(d1[dancerId], d2[dancerId])) {
            return false;
        }
    }
    return true;
}
export function compareDancer(d1, d2) {
    return d1 && d2
        && d1.id === d2.id
        && d1.name === d2.name
        && d1.color === d2.color
        && d1.icon === d2.icon;
}

export function compareTimeMarkers(tm1, tm2) {
    if (Object.keys(tm1).length !== Object.keys(tm2).length) {
        return false;
    }
    for (let tmId in tm1) {
        if (!tm2.hasOwnProperty(tmId)
            || tm1[tmId].id !== tm2[tmId].id
            || tm1[tmId].name !== tm2[tmId].name
            || tm1[tmId].time !== tm2[tmId].time
            || !comparePositions(tm1[tmId].positions, tm2[tmId].positions)) {
            return false;
        }
    }
    return true;
}

export function comparePositions(pos1, pos2) {
    if (Object.keys(pos1).length !== Object.keys(pos2).length) {
        return false;
    }
    for (let dancerId in pos1) {
        if (!comparePosition(pos1[dancerId], pos2[dancerId])) {
            return false;
        }
    }
    return true;
}

export function comparePosition(pos1, pos2) {
    if (!pos1 || !pos2) {
        return false;
    }
    if (!pos1.hasOwnProperty("linked")) {
        pos1.linked = [];
    }
    if (!pos2.hasOwnProperty("linked")) {
        pos2.linked = [];
    }
    pos1.linked.sort();
    pos2.linked.sort();
    if (JSON.stringify(pos1) !== JSON.stringify(pos2)) {
        return false;
    }
    return pos1 !== undefined
        && pos2 !== undefined
        && pos1.id === pos2.id
        && pos1.x === pos2.x
        && pos1.y === pos2.y;
}