import React from 'react';


export const icon0 = <g>
    <circle cx="12" cy="12" r="11" fill="var(--color)" />
</g>
export const icon1 = <g>
    <ellipse stroke-width="2" ry="11" rx="11" cy="12" cx="12" stroke="var(--color)" fill-opacity="0" />
    <line transform="rotate(45 12 12)" stroke-width="3" y2="22.5" x2="12" y1="1.5" x1="12" stroke="var(--color)" />
    <line transform="rotate(-45 12 12)" stroke-width="3" y2="22.5" x2="12" y1="1.5" x1="12" stroke="var(--color)" />
</g>;
export const icon2 = <g>
    <ellipse stroke-width="2" ry="11" rx="11" cy="12" cx="12" stroke="var(--color)" fill="none" />
    <line stroke-width="2" y2="10" x2="12" y1="1.5" x1="12" stroke="var(--color)" />
    <path stroke="var(--color)" d="m3.83326,18.21569l8.16674,-11.00012l8.16674,11.00012l-14.33349,0z" stroke-width="1" fill="var(--color)" />
</g>;
export const icon3 = <g>
    <ellipse stroke-width="2" ry="11" rx="11" cy="12" cx="12" stroke="var(--color)" fill="none" />
    <line stroke-width="2" y2="23" x2="12" y1="7" x1="12" stroke="var(--color)" fill="none" />
    <path transform="rotate(180 12 12)" stroke="var(--color)" d="m3.83326,17.21568l8.16674,-11.00012l8.16674,11.00012l-14.33349,0z" stroke-width="2" fill="var(--color)" />
</g>;
export const icon4 = <g>
    <ellipse stroke-width="2" ry="11" rx="11" id="svg_1" cy="12" cx="12" stroke="var(--color)" fill="none" />
    <line stroke-width="3" id="svg_9" y2="23" x2="12" y1="1" x1="12" stroke="var(--color)" />
    <line stroke-width="3" id="svg_12" y2="12" x2="23" y1="12" x1="1" stroke="var(--color)" />
</g>
export const icon5 = <g>
 <ellipse stroke-width="2" ry="11" rx="11" cy="12" cx="12" stroke="var(--color)" fill="none"/>
 <ellipse ry="5" rx="5" cy="12" cx="12" stroke="var(--color)" fill="var(--color)"/>
</g>;
export const icon6 = <g>
    <ellipse stroke="var(--color)" cx="12" cy="12" id="svg_1" rx="11" ry="11" stroke-width="2" fill="none" />
    <ellipse stroke-width="6" stroke="var(--color)" fill="none" cx="12" cy="12" id="svg_2" rx="8" ry="8" />
</g>

export const getIcon = (iconName) => {
    let icon = icon0;
    if (iconName === "icon0") {
        icon = icon0;
    } else if (iconName === "icon1") {
        icon = icon1;
    } else if (iconName === "icon2") {
        icon = icon2;
    } else if (iconName === "icon3") {
        icon = icon3;
    } else if (iconName === "icon4") {
        icon = icon4;
    } else if (iconName === "icon5") {
        icon = icon5;
    } else if (iconName === "icon6") {
        icon = icon6;
    }
    return icon;
}


export default function Dancers({
    numbersSpace = 30,
    width = 800,
    height = 800,
    // Size of the dance area in selected unit
    areaSizeX = 16,
    areaSizeY = 16,
    dancerRadius = 24,
    dancers = {},
    positions = {}
}) {
    let positionsDrawn = [];

    let centerX = width / 2;
    let centerY = height / 2;
    let cellSize = Math.min((width - 2 * numbersSpace) / areaSizeX, (height - 2 * numbersSpace) / areaSizeY);

    for (let dancerId in dancers) {
        let dancer = dancers[dancerId];
        let position = positions[dancerId];
        let x = centerX + position.x * cellSize - dancerRadius;
        let y = centerY + position.y * cellSize - dancerRadius;
        let icon = getIcon(dancer.icon)
        positionsDrawn.push(
            <g transform={"translate(" + x + " " + y + ") scale(" + ((2 * dancerRadius) / 24) + ")"} style={{ "--color": dancer.color }}>
                {icon}
            </g>);
        //<circle cx={centerX+position.x*cellSize} cy={centerY+position.y*cellSize} r={dancerRadius} fill={dancer.color} />)
    }
    return (
        <g>
            {positionsDrawn}
        </g>
    )
}