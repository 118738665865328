import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Slider, styled, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function Settings({
    isReadOnly = false,
    settings = {
        snap: 0.5,
        dancerRadius: 12
    }, setSettings = () => null,
    track = {},
    setTrack = () => null,
    setUpdateTrack = () => null
}) {
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [snapshotsOpen, setSnapshotsOpen] = useState(false);
    const [restoreSnapshot, setRestoreSnapshot] = useState({ created: 0 });
    const [deleteSnapshot, setDeleteSnapshot] = useState({ created: 0 });

    function uploadFile(event) {
        event.preventDefault()

        const file = event.target.files[0];
        const data = new FormData();
        data.append('file', file);
        data.append('fileName', file.name);
        fetch('/api/v1/track/' + track.id + '/music', {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            body: data
        }).then((response) => {
            return setUpdateTrack(true);
        })
    }

    const handleSnapChange = (event, newValue) => {
        if (typeof newValue === 'number') {
            setSettings({
                ...settings,
                snap: newValue
            });
        }
    };

    function handleTakeSnapshot() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch('/api/v1/track/' + track.id + '/snapshot', requestOptions)
            .then(response => {
                setUpdateTrack(true);
            })
            .catch((err) => {
                setUpdateTrack(true);
            });
    }

    function handleRestoreSnapshot() {
        setRestoreSnapshot({ created: 0 });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch('/api/v1/track/' + track.id + '/snapshot/restore/' + restoreSnapshot.uuid, requestOptions)
            .then(response => {
                setUpdateTrack(true);
            })
            .catch((err) => {
                setUpdateTrack(true);
            });
    }

    function handleDeleteSnapshot() {
        setDeleteSnapshot({ created: 0 });
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch('/api/v1/track/' + track.id + '/snapshot/' + deleteSnapshot.uuid, requestOptions)
            .then(response => {
                setUpdateTrack(true);
            })
            .catch((err) => {
                setUpdateTrack(true);
            });
    }

    let snapshots = [];
    if (track.snapshots) {
        for (let snapshot of track.snapshots) {
            const time = new Date(snapshot.created * 1000);
            const created = time.toLocaleDateString('de-DE') + " " + time.toLocaleTimeString();
            snapshots.push(<ListItem key="snapshots">
                <ListItemIcon>
                    <BookmarkIcon />
                </ListItemIcon>
                <ListItemText primary={created} />
                <IconButton
                    size="large"
                    onClick={() => {
                        setSnapshotsOpen(false);
                        setRestoreSnapshot(snapshot);
                    }}>
                    <SettingsBackupRestoreIcon />
                </IconButton>
                <IconButton
                    size="large"
                    onClick={() => {
                        setDeleteSnapshot(snapshot)
                    }}>
                    <DeleteIcon />
                </IconButton>
            </ListItem>);
        }
    }

    return (
        <>
            <IconButton
                color="inherit"
                size="large"
                aria-label="open settings"
                edge="end"
                style={{ marginRight: '0px' }}
                onClick={() => setSettingsOpen(true)}>
                <SettingsIcon />
            </IconButton>

            <Drawer
                anchor="right"
                open={settingsOpen}
                onClose={() => setSettingsOpen(false)}>
                <Box>
                    <List style={{ minWidth: "240px" }}>
                        <ListItem key="settingsText">
                            <Typography variant="h4" gutterBottom>
                                Einstellungen
                            </Typography>
                        </ListItem>
                        {!isReadOnly && <ListItem key={"uploadMusic"}>
                            <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                                Musik hochladen
                                <VisuallyHiddenInput type="file" onChange={uploadFile} />
                            </Button>
                        </ListItem>}
                        {!isReadOnly && <ListItem key="setName">
                            <TextField
                                id="trackname"
                                label="Track name"
                                type="text"
                                InputLabelProps={{ shrink: true }}
                                value={track.name}
                                onChange={(e) => setTrack({ ...track, name: e.target.value })}
                            />
                        </ListItem>}
                        {!isReadOnly && <ListItem key="setLength">
                            <TextField
                                id="length"
                                label="Länge"
                                type="number"
                                InputLabelProps={{ shrink: true }}
                                value={track.length / 1000}
                                onChange={(e) => setTrack({ ...track, length: parseInt(e.target.value) * 1000 })}
                            />
                        </ListItem>}

                        {!isReadOnly && <ListItem key="SetSnap">
                            <Box sx={{ width: 200, margin: "20px" }}>
                                <Typography gutterBottom>
                                    Snap
                                </Typography>
                                <Slider
                                    style={{ width: "100%" }}
                                    aria-label="snap"
                                    defaultValue={settings.snap}
                                    step={0.25}
                                    min={0}
                                    max={0.5}
                                    valueLabelDisplay="auto"
                                    onChange={handleSnapChange}
                                    marks={[
                                        { value: 0, label: "aus" },
                                        { value: 0.25, label: "0,25" },
                                        { value: 0.5, label: "0,5" }]}
                                />
                            </Box>
                        </ListItem>}

                        {!isReadOnly && <ListItem key="snapshots">
                            <Box sx={{ width: 200, margin: "20px" }}>
                                <ListItemButton
                                    onClick={() => {
                                        setSettingsOpen(false);
                                        setSnapshotsOpen(true);
                                    }}
                                >
                                    <ListItemIcon>
                                        <BookmarksIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Snapshots" />
                                </ListItemButton>
                            </Box>
                        </ListItem>}
                    </List>
                </Box>
            </Drawer>

            <Dialog
                keepMounted
                open={snapshotsOpen}
                onClose={() => setSnapshotsOpen(false)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <DialogTitle>Snapshots</DialogTitle>
                <DialogContent>
                    <Box style={{ paddingBottom: "20px" }}>
                        <Divider />
                    </Box>
                    <List>
                        {snapshots}
                        <ListItem key={"newSnapshotDivider"}>
                            <Divider />
                        </ListItem>
                        <ListItem key={"newSnapshot"}>
                            <ListItemButton
                                onClick={(e) => {
                                    handleTakeSnapshot();
                                }}>
                                <ListItemIcon
                                    size="large">
                                    <BookmarkAddIcon />
                                </ListItemIcon>
                                <ListItemText primary="Neuer Snapshot" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{ margin: "10px" }}
                        onClick={() => setSnapshotsOpen(false)}>
                        Schließen</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                keepMounted
                open={restoreSnapshot.created > 0}
                onClose={() => setRestoreSnapshot({ created: 0 })}
            >
                <DialogTitle>Snapshot wiederherstellen?</DialogTitle>
                <DialogContent>
                    <Box style={{ paddingBottom: "20px" }}>
                        <Divider />
                    </Box>
                    <Box style={{ paddingBottom: "20px" }}>
                        <Typography variant="body1">
                            Soll der Snapshot vom {new Date(restoreSnapshot.created * 1000).toLocaleDateString()} {new Date(restoreSnapshot.created * 1000).toLocaleTimeString()} wirklich wiederhergestellt werden?
                        </Typography>
                    </Box>
                    <Box>
                        <Alert severity="warning">Falls der aktuelle Stand noch nicht gespeichert wurde, geht er dauerhaft verloren!</Alert>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" style={{ margin: "10px" }}
                        onClick={() => {
                            setRestoreSnapshot({ created: 0 });
                        }}>Abbrechen</Button>
                    <Button variant="contained" style={{ margin: "10px" }}
                        onClick={() => handleRestoreSnapshot()}>
                        Wiederherstellen</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                keepMounted
                open={deleteSnapshot.created > 0}
                onClose={() => setDeleteSnapshot({ created: 0 })}
            >
                <DialogTitle>Snapshot löschen?</DialogTitle>
                <DialogContent>
                    <Box style={{ paddingBottom: "20px" }}>
                        <Divider />
                    </Box>
                    <Box style={{ paddingBottom: "20px" }}>
                        <Typography variant="body1">
                            Soll der Snapshot vom {new Date(deleteSnapshot.created * 1000).toLocaleDateString()} {new Date(deleteSnapshot.created * 1000).toLocaleTimeString()} wirklich gelöscht werden?
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" style={{ margin: "10px" }}
                        onClick={() => {
                            setDeleteSnapshot({ created: 0 });
                        }}>Abbrechen</Button>
                    <Button variant="contained" style={{ margin: "10px" }} color="error"
                        onClick={() => handleDeleteSnapshot()}>
                        Löschen</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}