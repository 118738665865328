import React from 'react';

export default function DanceArea({
    numbersSpace = 30,
    width = 800,
    height = 800,
    // Size of the dance area in selected unit
    areaSizeX = 16,
    areaSizeY = 16
}) {
    let lines = [];
    let numbers = []
    let cellSize = Math.min((width - 2 * numbersSpace) / areaSizeX, (height - 2 * numbersSpace) / areaSizeY);
    let marginX = numbersSpace + (width-2*numbersSpace - (areaSizeX * cellSize))/2;
    let marginY = numbersSpace + (height-2*numbersSpace - (areaSizeY * cellSize))/2;
    for (let x = 0; x < areaSizeX + 1; x++) {
        lines.push(
            <line x1={marginX + x * cellSize} y1={marginY}
                x2={marginX + x * cellSize} y2={marginY + areaSizeY*cellSize}
                stroke="black" strokeWidth={areaSizeX / 2 === x ? 3 : 1} />);
        numbers.push(<text textAnchor="middle" dominant-baseline="middle" fontSize={numbersSpace / 1.5}
            x={marginX + x * cellSize}
            y={marginY - numbersSpace / 2}>
            {x - areaSizeX / 2}
        </text>);
    }
    for (let y = 0; y < areaSizeY + 1; y++) {
        lines.push(
            <line x1={marginX} y1={marginY + y * cellSize}
                x2={marginX + areaSizeX * cellSize} y2={marginY + y * cellSize}
                stroke="black" strokeWidth={areaSizeX / 2 === y ? 3 : 1} />);
        numbers.push(<text textAnchor="middle" dominant-baseline="middle" fontSize={numbersSpace / 1.5}
            x={marginX - numbersSpace / 2}
            y={marginY + y * cellSize}>
            {y - areaSizeY / 2}
        </text>);
    }
    return (
        <g>
            {lines}
            {numbers}
        </g>
    )
}