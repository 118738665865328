import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FilterIcon from '@mui/icons-material/Filter';
import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from 'react';
import { formatMilliSeconds } from '../utils/ChoreoUtils';


export default function Pictures({
    isReadOnly = false,
    markers = {},
    setMarkers = () => null,
    time = 0,
    setTime = () => null
}) {
    const [markersOpen, setMarkersOpen] = useState(false);
    const [editMarkerTime, setEditMarkerTime] = useState(null);
    const [editMarkerOpen, setEditMarkerOpen] = useState(false);
    const [editMarkerName, setEditMarkerName] = useState("");
    const [editMarkerNewTime, setEditMarkerNewTime] = useState(0);
    const [timeUpdated, setTimeUpdated] = useState(0);
    const [timeText, setTimeText] = useState("00:00.0");
    const [timeTextValid, setTimeTextValid] = useState(true);

    useEffect(() => {
        setTimeText(formatMilliSeconds(editMarkerNewTime));
        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMarkerOpen, timeUpdated]);

    useEffect(() => {
        const re = /^(\d\d?):(\d\d?)(?:\.(\d))?$/i;
        const found = timeText.match(re);
        if (found) {
            let minutes = parseInt(found[1]);
            let seconds = parseInt(found[2]);
            let ms = 0;
            if (found[3]) {
                ms = parseInt(found[3]);
            }
            setEditMarkerNewTime(minutes * 60 * 1000 + seconds * 1000 + ms * 100);
            setTimeTextValid(true);
        }
        else {
            setTimeTextValid(false);
        }
    }, [timeText]);

    const deleteMarker = (time) => {
        let newMarkers = {
            ...markers
        };
        delete newMarkers[time];
        setMarkers(newMarkers);
    }

    let markersList = [];

    for (let markerId in markers) {
        let marker = markers[markerId];
        markersList.push(
            <ListItem key={marker.time} disablePadding
                style={{ backgroundColor: marker.time === time ? "#DDD" : null, minWidth: "240px" }}>
                <ListItemButton
                    onClick={(e) => setTime(marker.time)}>
                    <ListItemIcon>
                        <CropOriginalIcon />
                    </ListItemIcon>
                    <ListItemText primary={marker.name} secondary={formatMilliSeconds(marker.time)} />
                </ListItemButton>
                {!isReadOnly && marker.time > 0 ? <>
                    <IconButton
                        size="large"
                        onClick={() => {
                            setEditMarkerTime(marker.time);
                            setEditMarkerNewTime(marker.time);
                            setEditMarkerName(marker.name);
                            setMarkersOpen(false);
                            setEditMarkerOpen(true);
                        }}>
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        size="large"
                        onClick={(e) => deleteMarker(marker.time)}>
                        <DeleteIcon />
                    </IconButton> </> : <></>}
            </ListItem>);
    }

    const editMarker = () => {
        let newMarkers = { ...markers };
        delete newMarkers[editMarkerTime];
        newMarkers[editMarkerNewTime] = {
            ...markers[editMarkerTime],
            name: editMarkerName,
            time: editMarkerNewTime
        };
        setEditMarkerTime(null);
        setEditMarkerNewTime(null);
        setEditMarkerName("");
        setEditMarkerOpen(false);
        setMarkers({ ...newMarkers });
    }

    return (
        <>
            <IconButton
                color="inherit"
                size="large"
                aria-label="open pictures"
                edge="end"
                style={{ marginRight: '0px' }}
                onClick={() => setMarkersOpen(true)}
            >
                <FilterIcon />
            </IconButton>
            <Drawer
                anchor="right"
                open={markersOpen}
                onClose={() => setMarkersOpen(false)}
            >
                <Box>
                    <List>
                        {markersList}
                    </List>
                </Box>
            </Drawer>
            <Dialog
                keepMounted
                open={editMarkerOpen}
                onClose={() => setEditMarkerOpen(false)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <DialogTitle>Key-Punkt bearbeiten</DialogTitle>
                <DialogContent>
                    <Box style={{ paddingBottom: "20px" }}>
                        <Divider />
                    </Box>
                    <Box style={{ paddingBottom: "20px" }}>
                        <TextField
                            required
                            id="outlined-required"
                            label="Name"
                            value={editMarkerName !== null ? editMarkerName : ""}
                            defaultValue=""
                            onChange={(e) => setEditMarkerName(e.target.value)}
                        />
                    </Box>
                    <Box style={{ paddingBottom: "20px" }}>
                        <TextField
                            error={!timeTextValid}
                            helperText={timeTextValid ? "" : "Zeit muss im Format 00:00.0 sein"}
                            inputProps={{ inputMode: 'numeric', pattern: '\\d{1-2}:\\d{1-2}(\\.\\d)?' }}
                            value={timeText}
                            onChange={(e) => setTimeText(e.target.value)}
                        />
                    </Box>
                    <Box>
                        <ButtonGroup variant="outlined" aria-label="outlined button group">
                            <Button onClick={() => {
                                setEditMarkerNewTime(editMarkerNewTime - 1000);
                                setTimeUpdated(editMarkerNewTime - 1000);
                            }}>- 00:01.0</Button>
                            <Button onClick={() => {
                                setEditMarkerNewTime(editMarkerNewTime - 100);
                                setTimeUpdated(editMarkerNewTime - 100);
                            }}>- 00:00.1</Button>
                            <Button onClick={() => {
                                setEditMarkerNewTime(editMarkerNewTime + 100);
                                setTimeUpdated(editMarkerNewTime + 100);
                            }}>+ 00:00.1</Button>
                            <Button onClick={() => {
                                setEditMarkerNewTime(editMarkerNewTime + 1000);
                                setTimeUpdated(editMarkerNewTime + 1000);
                            }}>+ 00:01.0</Button>
                        </ButtonGroup>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" style={{ margin: "10px" }}
                        onClick={() => {
                            setEditMarkerTime(null);
                            setEditMarkerNewTime(null);
                            setEditMarkerName("");
                            setEditMarkerOpen(false);
                        }}>Abbrechen</Button>
                    <Button variant="contained" style={{ margin: "10px" }}
                        onClick={() => editMarker()}>
                        Aktualisieren</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}