import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/system';
import React from 'react';

import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';

import DancersMenu from './DancersMenu';
import Pictures from './Pictures';
import Settings from './Settings';

export default function ChoreoAppBar({
    isReadOnly = false,
    synced = false,
    settings = {},
    setSettings = () => null,
    positions = {},
    markers = {},
    setMarkers = () => null,
    track = {},
    setTrack = () => null,
    setUpdateTrack = () => null,
    time = 0,
    setTime = () => null
}) {
    return (
        <>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar>
                        <Box sx={{
                            flexGrow: -1,
                            margin: "0px 10px",
                            backgroundColor: "white",
                            borderRadius: "16px",
                            width: "32px",
                            height: "32px"
                        }}>
                            {synced ? <CloudDoneOutlinedIcon sx={{ top: "3px", position: "relative" }} color="primary"/> : <CloudOffOutlinedIcon sx={{ top: "3px", position: "relative" }} color="error" />}
                        </Box>
                        <Typography variant="h6" noWrap sx={{ flexGrow: 1, textAlign: "left" }} component="div">
                           { track.name ? track.name + " - " : "" }Choreo Picture Helper
                        </Typography>
                        <Box sx={{ display: { xs: 'flex' } }}>
                            <Pictures
                                isReadOnly={isReadOnly}
                                markers={markers}
                                setMarkers={setMarkers}
                                time={time}
                                setTime={setTime}
                            />

                            <DancersMenu
                                isReadOnly={isReadOnly}
                                positions={positions}
                                track={track}
                                setTrack={setTrack}
                            />

                            <Settings
                                isReadOnly={isReadOnly}
                                settings={settings}
                                setSettings={setSettings}
                                track={track}
                                setTrack={setTrack}
                                setUpdateTrack={setUpdateTrack}
                            />
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>


        </>
    );
}