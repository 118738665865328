import DeleteIcon from '@mui/icons-material/Delete';
import PeopleIcon from '@mui/icons-material/People';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SvgIcon, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';

import { SwatchesPicker } from 'react-color';
import { getIcon, icon0, icon1, icon2, icon3, icon4, icon5, icon6 } from '../components/Dancers';

export default function DancersMenu({
    isReadOnly = false,
    track = {},
    setTrack = () => null,
    positions = {}
}) {
    const [dancersOpen, setDancersOpen] = useState(false);
    const [editDancer, setEditDancer] = useState({});
    const [editDancerOpen, setEditDancerOpen] = useState(false);

    function handleEditDancer() {
        let newTimeMarkers = {
            ...track.timeMarkers
        }

        let dancerId = editDancer.id;
        if (dancerId === null) {
            dancerId = -1;
            for (let curDancerId in track.dancers) {
                dancerId = Math.min(curDancerId - 1, dancerId);
            }
            for (let markerId in newTimeMarkers) {
                newTimeMarkers[markerId].positions[dancerId] = {
                    x: 0,
                    y: 0
                };
            }
        }
        let newDancers = {
            ...track.dancers,
            [dancerId]: {
                ...editDancer,
                id: dancerId
            }
        };
        setEditDancer({});
        setEditDancerOpen(false);
        setTrack({
            ...track,
            dancers: newDancers,
            timeMarkers: newTimeMarkers
        });
    }

    const deleteDancer = (dancer) => {
        let newDancers = {
            ...track.dancers
        };
        delete newDancers[dancer.id];
        let newTimeMarkers = {
            ...track.timeMarkers
        }
        for (let markerId in newTimeMarkers) {
            delete newTimeMarkers[markerId].positions[dancer.id]
        }
        setTrack({
            ...track,
            dancers: newDancers,
            timeMarkers: newTimeMarkers
        });
    }

    let dancersList = [];

    for (let dancerId in track.dancers) {
        let dancer = track.dancers[dancerId];
        let positionText = "0:0";
        if (positions.hasOwnProperty(dancer.id)) {
            positionText = Math.round(positions[dancer.id].x * 100) / 100 + ":"
                + Math.round(positions[dancer.id].y * 100) / 100;
        }
        dancersList.push(
            <ListItem key={dancer.id} disablePadding>
                <ListItemButton
                    onClick={() => {
                        if (!isReadOnly) {
                            setEditDancer({ ...dancer });
                            setDancersOpen(false);
                            setEditDancerOpen(true);
                        }
                    }}>
                    <ListItemIcon>
                        <SvgIcon
                            style={{ "--color": dancer.color }}>
                            {getIcon(dancer.icon)}
                        </SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary={dancer.name} secondary={positionText} />
                </ListItemButton>
                {!isReadOnly &&
                    <IconButton
                        size="large"
                        onClick={(e) => deleteDancer(dancer)}>
                        <DeleteIcon />
                    </IconButton>}
            </ListItem>);
    }

    return (
        <>
            <IconButton
                color="inherit"
                size="large"
                aria-label="open pictures"
                edge="end"
                style={{ marginRight: '0px' }}
                onClick={() => setDancersOpen(true)}
            >
                <PeopleIcon />
            </IconButton>
            <Drawer
                anchor="right"
                open={dancersOpen}
                onClose={() => setDancersOpen(false)}
            >
                <Box>
                    <List style={{ minWidth: "240px" }}>
                        {dancersList}
                    </List>
                    <Divider />
                    <List>
                        {!isReadOnly &&
                            <ListItem key={"newDancer"} disablePadding>
                                <ListItemButton
                                    onClick={(e) => {
                                        setDancersOpen(false);
                                        setEditDancer({
                                            id: null,
                                            name: "",
                                            icon: "icon0",
                                            color: "#000"
                                        })
                                        setEditDancerOpen(true);
                                    }}>
                                    <ListItemIcon
                                        size="large">
                                        <PersonAddIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Neuer Tänzer" />
                                </ListItemButton>
                            </ListItem>}
                    </List>
                </Box>
            </Drawer>
            <Dialog
                keepMounted
                open={editDancerOpen}
                onClose={() => setEditDancerOpen(false)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <DialogTitle>{editDancer.id !== null ? "Tänzer bearbeiten" : "Neuen Tänzer hinzufügen"}</DialogTitle>
                <DialogContent>
                    <Box style={{ paddingBottom: "20px" }}>
                        <Divider />
                    </Box>
                    <Box style={{ paddingBottom: "20px" }}>
                        <TextField
                            required
                            id="outlined-required"
                            label="Name"
                            value={editDancer.name !== null ? editDancer.name : ""}
                            defaultValue={editDancer.name}
                            onChange={(e) => setEditDancer({ ...editDancer, name: e.target.value })}
                        />
                    </Box>
                    <Box style={{ paddingBottom: "20px" }}>
                        <ToggleButtonGroup
                            value={editDancer.icon}
                            exclusive
                            onChange={(e, newA) => setEditDancer({ ...editDancer, icon: newA })}
                            aria-label="text alignment"
                            style={{ "--color": editDancer.color }}
                        >
                            <ToggleButton value="icon0">
                                <SvgIcon>
                                    {icon0}
                                </SvgIcon>
                            </ToggleButton>
                            <ToggleButton value="icon1">
                                <SvgIcon>
                                    {icon1}
                                </SvgIcon>
                            </ToggleButton>
                            <ToggleButton value="icon2">
                                <SvgIcon>
                                    {icon2}
                                </SvgIcon>
                            </ToggleButton>
                            <ToggleButton value="icon3">
                                <SvgIcon>
                                    {icon3}
                                </SvgIcon>
                            </ToggleButton>
                            <ToggleButton value="icon4">
                                <SvgIcon>
                                    {icon4}
                                </SvgIcon>
                            </ToggleButton>
                            <ToggleButton value="icon5">
                                <SvgIcon>
                                    {icon5}
                                </SvgIcon>
                            </ToggleButton>
                            <ToggleButton value="icon6">
                                <SvgIcon>
                                    {icon6}
                                </SvgIcon>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                    <Box style={{ paddingBottom: "20px" }}>
                        <SwatchesPicker
                            disableAlpha={true}
                            color={editDancer.color !== null ? editDancer.color : "#000"}
                            onChange={(e) => setEditDancer({ ...editDancer, color: e.hex })} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" style={{ margin: "10px" }}
                        onClick={() => {
                            setEditDancer({});
                            setEditDancerOpen(false);
                        }}>Abbrechen</Button>
                    <Button variant="contained" style={{ margin: "10px" }}
                        onClick={() => handleEditDancer()}>
                        {editDancer.id !== null ? "Tänzer bearbeiten" : "Tänzer erstellen"}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}