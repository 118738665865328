import React, { useEffect, useRef } from 'react';
import DanceArea from './DanceArea';
import Dancers from './Dancers';

export default function ChoreoBaseSvg({
    numbersSpace = 30,
    // Size of the dance area in selected unit
    areaSizeX = 16,
    areaSizeY = 16,
    isReadOnly = false,
    snap = 0.5,
    dancerRadius = 12,
    dancers = {},
    isOnMarker = false,
    positions = {},
    setPositions = () => null,
}) {
    const [dragStartTime, setDragStartTime] = React.useState(-1);
    const [startX, setStartX] = React.useState(-1);
    const [startY, setStartY] = React.useState(-1);
    const [componentRect, setComponentRect] = React.useState({
        x: 0,
        y: 0,
        width: 800,
        height: 800
    });
    const [selectedPositions, setSelectedPositions] = React.useState({});
    const [selectionRect, setSelectionRect] = React.useState({ x1: -1, y1: -1, x2: -1, y2: -1 });
    const [selectionRectStart, setSelectionRectStart] = React.useState({ x1: -1, y1: -1, x2: -1, y2: -1 });

    function handleTouchStart(e) {
        e.preventDefault();
        if (e.touches.length === 1) {
            handleDragStart({ pointerX: e.touches[0].clientX, pointerY: e.touches[0].clientY });
        } else {
            setDragStartTime(-1);
        }
    }
    function handleMouseDown(e) {
        e.preventDefault();
        handleDragStart({ pointerX: e.clientX, pointerY: e.clientY });
    }
    function handleDragStart({ pointerX, pointerY }) {
        if (!isOnMarker || isReadOnly) {
            return;
        }
        console.log("Start: " + pointerX + ":" + pointerY);
        setDragStartTime(Date.now());
        if (Object.keys(selectedPositions).length === 0) {
            let selectedPositions = {};
            let selectedDancerId = null;

            let centerX = componentRect.width / 2;
            let centerY = componentRect.height / 2;
            let cellSize = Math.min((componentRect.width - 2 * numbersSpace) / areaSizeX, (componentRect.height - 2 * numbersSpace) / areaSizeY);

            let boundingRect = elementRef.current.getBoundingClientRect();
            for (let dancerId in positions) {
                let position = positions[dancerId];
                let x = centerX + position.x * cellSize;
                let y = centerY + position.y * cellSize;
                let diffX = x - (pointerX - boundingRect.x);
                let diffY = y - (pointerY - boundingRect.y);
                if (Math.sqrt(diffX * diffX + diffY * diffY) < dancerRadius) {
                    selectedDancerId = dancerId;
                }
            }
            if (selectedDancerId !== null) {
                selectedPositions[selectedDancerId] = { ...positions[selectedDancerId] };
                if (positions[selectedDancerId].hasOwnProperty("linked")) {
                    for (let dancerId of positions[selectedDancerId].linked) {
                        selectedPositions[dancerId] = { ...positions[dancerId] };
                    }
                }
                setStartX(pointerX);
                setStartY(pointerY);
                setSelectedPositions(selectedPositions);
            } else {
                setSelectionRect({
                    x1: pointerX - componentRect.x,
                    y1: pointerY - componentRect.y,
                    x2: pointerX - componentRect.x,
                    y2: pointerY - componentRect.y
                });
            }
        } else {
            let newPositions = {};
            for (let dancerId in selectedPositions) {
                newPositions[dancerId] = { ...positions[dancerId] };
            }
            setSelectedPositions(newPositions);

            setStartX(pointerX);
            setStartY(pointerY);
            setSelectionRectStart({ ...selectionRect });
        }
    }

    function handleMouseMove(e) {
        handleMove({ pointerX: e.clientX, pointerY: e.clientY });
    }

    function handleTouchMove(e) {
        handleMove({ pointerX: e.changedTouches[0].clientX, pointerY: e.changedTouches[0].clientY });
    }

    function handleMove({ pointerX, pointerY }) {
        if (Object.keys(selectedPositions).length === 0 && dragStartTime > 0) {
            setSelectionRect({
                ...selectionRect,
                x2: pointerX - componentRect.x,
                y2: pointerY - componentRect.y
            });
        } else if (dragStartTime > 0) {
            console.log("Move: " + pointerX + ":" + pointerY + " Diff: " + (pointerX - startX) + ":" + (pointerY - startY));
            let cellSize = Math.min((componentRect.width - 2 * numbersSpace) / areaSizeX, (componentRect.height - 2 * numbersSpace) / areaSizeY);
            let newPositions = {};
            for (let dancerId in selectedPositions) {
                let x = selectedPositions[dancerId].x + ((pointerX - startX) / cellSize);
                let y = selectedPositions[dancerId].y + ((pointerY - startY) / cellSize);
                if (snap > 0) {
                    x = Math.round(x / snap) * snap;
                    y = Math.round(y / snap) * snap;
                }
                x = Math.max(-1 * areaSizeX / 2, Math.min(areaSizeX / 2, x));
                y = Math.max(-1 * areaSizeY / 2, Math.min(areaSizeY / 2, y));
                newPositions[dancerId] = {
                    ...positions[dancerId],
                    x: x,
                    y: y,
                };
            }
            setPositions({
                ...positions,
                ...newPositions
            });
            if (selectionRect.x1 >= 0) {
                setSelectionRect({
                    x1: selectionRectStart.x1 + pointerX - startX,
                    y1: selectionRectStart.y1 + pointerY - startY,
                    x2: selectionRectStart.x2 + pointerX - startX,
                    y2: selectionRectStart.y2 + pointerY - startY
                });
            }
        }
    }

    function handleMoveEnd() {
        if (Date.now() - dragStartTime < 500
            && Math.abs(selectionRectStart.x1 - selectionRect.x1) < 5
            && Math.abs(selectionRectStart.y1 - selectionRect.y1) < 5) {
            setStartX(-1);
            setStartY(-1);
            setSelectedPositions({});
            setSelectionRect({ x1: -1, y1: -1, x2: -1, y2: -1 })
            setSelectionRectStart({ x1: -1, y1: -1, x2: -1, y2: -1 });
        } else {
            if (selectionRect.x1 === -1) {
                setSelectedPositions({});
            }
            else if (Object.keys(selectedPositions).length === 0) {
                let centerX = componentRect.width / 2;
                let centerY = componentRect.height / 2;
                let cellSize = Math.min((componentRect.width - 2 * numbersSpace) / areaSizeX, (componentRect.height - 2 * numbersSpace) / areaSizeY);

                let x1 = Math.min(selectionRect.x1, selectionRect.x2);
                let x2 = Math.max(selectionRect.x1, selectionRect.x2);
                let y1 = Math.min(selectionRect.y1, selectionRect.y2);
                let y2 = Math.max(selectionRect.y1, selectionRect.y2);

                let newSelectedPositions = {};
                for (let dancerId in positions) {
                    let position = positions[dancerId];
                    let x = centerX + position.x * cellSize;
                    let y = centerY + position.y * cellSize;
                    if (x >= x1 && x <= x2 && y >= y1 && y <= y2) {
                        newSelectedPositions[dancerId] = {
                            ...position
                        }
                    };
                }
                if (Object.keys(newSelectedPositions).length > 0) {
                    setSelectedPositions(newSelectedPositions);
                    setSelectionRectStart({ ...selectionRect });
                } else {
                    setStartX(-1);
                    setStartY(-1);
                    setSelectedPositions({});
                    setSelectionRect({ x1: -1, y1: -1, x2: -1, y2: -1 })
                    setSelectionRectStart({ x1: -1, y1: -1, x2: -1, y2: -1 });
                }
            }
        }
        setDragStartTime(-1);
    }

    const elementRef = useRef()

    useEffect(() => {
        setStartX(-1);
        setStartY(-1);
        setSelectedPositions({});
        setSelectionRect({ x1: -1, y1: -1, x2: -1, y2: -1 })
        setSelectionRectStart({ x1: -1, y1: -1, x2: -1, y2: -1 });
    }, [isOnMarker]);

    useEffect(() => {
        const element = elementRef?.current;
        if (!element) return;

        const observer = new ResizeObserver(() => {
            setComponentRect({
                x: element.getBoundingClientRect().x,
                y: element.getBoundingClientRect().y,
                width: element.getBoundingClientRect().width,
                height: element.getBoundingClientRect().height
            });
        });

        observer.observe(element);
        return () => {
            observer.disconnect();
        };
    }, [])

    return (
        <svg ref={elementRef} style={{ width: "100%", height: "100%", touchAction: "none" }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMoveEnd}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleMoveEnd}
            onTouchCancel={handleMoveEnd}>
            <DanceArea numbersSpace={numbersSpace}
                width={componentRect.width}
                height={componentRect.height}
                areaSizeX={areaSizeX}
                areaSizeY={areaSizeY}
            />
            <rect x={Math.min(selectionRect.x1, selectionRect.x2)}
                y={Math.min(selectionRect.y1, selectionRect.y2)}
                width={Math.abs(selectionRect.x2 - selectionRect.x1)}
                height={Math.abs(selectionRect.y2 - selectionRect.y1)}
                fill-opacity="0.4"
                style={{
                    fill: "rgba(200,200,200)",
                    strokeWidth: 1,
                    stroke: "rgb(0,0,0)"
                }} />
            <Dancers numbersSpace={numbersSpace}
                width={componentRect.width}
                height={componentRect.height}
                areaSizeX={areaSizeX}
                areaSizeY={areaSizeY}
                dancers={dancers}
                positions={positions}
                dancerRadius={dancerRadius} />
        </svg>
    )
}